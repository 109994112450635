@import url(https://fonts.googleapis.com/css?family=Orbitron&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: black;
    overflow: hidden;
}

header {
    min-height: 60px;
    padding-left: 0;
    padding-right: 0;
}

.header-cont {
    position: fixed;
    margin-right: 20px;
    font-family: Orbitron;
    width: 100%;
    color: #164AFF;
    text-align: right;
    display: inline-block;
}

header h1 {
    padding-right: 0.rem;
    font-size: 1.7rem;
    float: right;
}

header button {
    background: transparent;
    outline: none;
    border: none;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
    height: 30px;
    width: 30px;
    float: left;
}

header img {
    height: 30px;
    width: 30px;
}

.side-nav {
    height: 100%;
}

.description {
    scrollbar-width: none;
    -ms-overflow-style: none;
    font-family: Orbitron;
    color: white;
    position: relative;
    text-align: center;
    overflow-y: scroll;
    padding-bottom: 5rem;
}

::-webkit-scrollbar { 
    display: none;
}

.dir-icons {
    grid-area: dir-icons;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-view {
    display: grid;
    width: 100vw;
    height: 100vh;
    grid-template-areas: "side-nav" "description" "dir-icons";
    padding-bottom: 2.5rem;
}

@media only screen and (max-width: 900px) {
    /*.main-view {
        grid-template-columns: 100%;
        grid-template-rows: 10% auto;
        grid-template-areas: "side-nav" "description";
    }*/
    .main-view {
        grid-template-columns: 100%;
        grid-template-rows: 100%;
    }

    .side-nav {
        height : 100%;
    }

    .hide-nav {
        display: none;
    }

    .dir-icons {
        display: none;
    }

    .nav-cont {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .card {
        display: flex;
        flex-direction: column;
    }
    * {
        font-size: 18px;
    }
}

@media only screen and (min-width: 900px){
    .description {
        grid-area: description;
    }

    .side-nav {
        grid-area: side-nav;
    }
    
    .main-view {
        grid-template-columns: 23% auto 23%;
        grid-template-rows: 100%;
        grid-template-areas: "side-nav description dir-icons" "side-nav description dir-icons" "side-nav description dir-icons";
    }

    .header-cont button {
        display: none;
    }

    .card {
        display: flex;
        flex-direction: row;
    }
    .nav-cont {
        height: 90%;
        flex-direction: column;
    }
    * {
        font-size: 25px;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1200px){
    * {
        font-size: 25px;
    }
}

@media only screen and (min-width: 1200px) {
    * {
        font-size: 32px;
    }
}


/* Start of nav-bar*/

.nav-cont {
    position: fixed;
    display: flex;
    justify-content: space-evenly;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.side-nav button {
    display: block;
    font-family: Orbitron;
    text-transform: uppercase;
    text-align: left;
    background-color: transparent;
    font-size: 0.9rem;
    font-weight: bold;
    border: none;
    color: #164AFF;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin: 0 5%;
    outline: none;
}

.side-nav button:hover {
    font-size: 1.1rem;
    color: #f1f1f1;
}


/* End of nav-bar*/


/* Middle content*/


/* Start of about */

.about {
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    height: 100%;
}

.photo {
    grid-row-start: 1;
    grid-row-end: 2;
    position: relative;
    text-align: center;
}

.my-dis {
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: justify;
    padding: 1rem 1rem;
    font-size: 2rem;
}

#big-circle {
    -webkit-animation: big-c 4s infinite linear;
            animation: big-c 4s infinite linear;
    -webkit-transform-origin: center;
            transform-origin: center;
}

#med-circle {
    -webkit-animation: med-c 3s infinite linear;
            animation: med-c 3s infinite linear;
    -webkit-transform-origin: center;
            transform-origin: center;
}

#small-circle {
    -webkit-animation: big-c 2s infinite linear;
            animation: big-c 2s infinite linear;
    -webkit-transform-origin: center;
            transform-origin: center;
}

@-webkit-keyframes big-c {
    from {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }
    to {
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
}

@keyframes big-c {
    from {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }
    to {
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
}

@-webkit-keyframes med-c {
    from {
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
    to {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }
}

@keyframes med-c {
    from {
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
    to {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }
}

#batouch {
    position: absolute;
    width: 200px;
    height: 200px;
    margin-top: 50px;
    margin-left: 50px;
}


/* End of about */


/* Start of hobies */

.hobies {
    font-family: Orbitron;
    color: white;
    font-weight: bold;
    font-size: 4rem;
}

.hobies li {
    margin: 2rem;
    list-style-type: none;
}

/* End of hobies */


/* Start of skills */

.skills {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.titles {
    margin-top: 1.1rem;
}

.skills h1 {
    font-size: 1.1rem;
    font-family: Orbitron;
    color: #164AFF;
}

.skills p {
    font-size: 0.9rem;
    font-family: Orbitron;
    color: #f1f1f1;
}


/* End of skills */


/* Start of education */

.education {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.education h1 {
    font-size: 1.1rem;
    font-family: Orbitron;
    color: #164AFF;
}

.education p {
    font-size: 0.9rem;
    font-family: Orbitron;
    color: #f1f1f1;
}

/* End of education */


/* Start of projects */

.projects {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 3rem;
}

.card {
    display: flex;
    border: 2px solid #164AFF;
    border-radius: 0.4rem;
    margin: 0.4rem;
}


.dis-pro {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}


.card h2 {
    color: #164AFF;
    font-size: 0.7rem;
}

.card p {
    font-size: 0.7rem;
}

.card img {
    width: 300px;
    height: 180px;
    margin: 0.2rem;
    border-radius: 0.2rem;
}
/* End of projects */


/* Start of formulaire */

.contact {
    height: 100%;
    width: 100%;
    margin-bottom: 4%;
}

.formulaire {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 600px;
}

input,
textarea,
.send-mail {
    font-family: Orbitron;
    width: 90%;
    max-width: 400px;
    height: 50px;
    background: transparent;
    border: 3px solid #164AFF;
    padding: 10px;
    margin-top: 10px;
    font-size: 0.7em;
    color: #f1f1f1;
}

::-webkit-input-placeholder {
    color: #f1f1f1;
    opacity: 0.7;
}

:-ms-input-placeholder {
    color: #f1f1f1;
    opacity: 0.7;
}

::-ms-input-placeholder {
    color: #f1f1f1;
    opacity: 0.7;
}

::placeholder {
    color: #f1f1f1;
    opacity: 0.7;
}

.message {
    height: 7rem;
    resize: none;
}

.send-mail {
    color: #164AFF;
}

.email-number {
    text-align: center;
}

.email-number a,
p {
    color: #f1f1f1;
    font-size: 0.8rem;
}

.social-icons {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
}


/* End of formulaire */


/* End of middle content */


/* Start of Icons div */

.icon-cont {
    position: fixed;
    width: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.icon-cont img {
    display: block;
    width: 2.5rem;
}


/* End of Icons div */
